// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideBarOpen {
    background-color: #002c6c;
    width: 13rem;
}

.sideBarClose {
    background-color: #002c6c;
    width: 4rem;
}

/* component.css */
.custom-button {
    display: block;
    border-radius: 0.25rem;
    padding: 0.5rem;
}
.custom-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

.clr {
    color: white;
}

.bg-blue-950 {
    background-color: #1e3a8a; /* Adjust this color as per your requirements */
}

.custom-button.active {
    background-color: #1e40af; /* Change this to your desired active color */
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA,kBAAkB;AAEd;IAAA,cAA0C;IAA1C,sBAA0C;IAA1C;AAA0C;AAA1C;IAAA,kBAA0C;IAA1C;AAA0C;;AAG9C;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB,EAAE,+CAA+C;AAC9E;;AAEA;IACI,yBAAyB,EAAE,6CAA6C;EAC1E","sourcesContent":[".sideBarOpen {\r\n    background-color: #002c6c;\r\n    width: 13rem;\r\n}\r\n\r\n.sideBarClose {\r\n    background-color: #002c6c;\r\n    width: 4rem;\r\n}\r\n\r\n/* component.css */\r\n.custom-button {\r\n    @apply block p-2 rounded hover:bg-blue-950;\r\n}\r\n\r\n.clr {\r\n    color: white;\r\n}\r\n\r\n.bg-blue-950 {\r\n    background-color: #1e3a8a; /* Adjust this color as per your requirements */\r\n}\r\n\r\n.custom-button.active {\r\n    background-color: #1e40af; /* Change this to your desired active color */\r\n  }\r\n  \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
